<template>
    <div class="action-more-profile">
        <div class="title f-bold" v-html="$translate('ACTION_MORE_PROFILE')" />
        <div class="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionMoreProfile',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        chatUser() {
            if (!this.chat) return

            return (this.chat || {}).user
        },
        body() {
            const body = this.$translate('ACTION_MORE_PROFILE')
            return body
            // return body.replace(/%s/, this.$nameOrNick(this.chatUser))
        },
    },
    methods: {
        // onClickProfile() {
        //     this.$router.push({ name: 'UserDetailPage', params: { userId: this.chatUser.id } })
        // },
    },
}
</script>
